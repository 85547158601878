import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha, colors } from '@mui/material';
import Page from '../../components/Page';
import Container from '../../components/Container';
import { Topbar, Footer } from 'layouts/Fixed/components';
import HeroImageRight from 'components/HeroImageRight/HeroImageRight';
import BlogWithLargeImage from 'components/BlogWithLargeImage';
import Form from 'components/Form';
import servicesData from '../../assets/services.json';
import ScrollToService from 'components/ScrollToService/ScrollToService';
import ScrollToTop from 'components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SecurityIcon from '@mui/icons-material/Security';
import BugReportIcon from '@mui/icons-material/BugReport';
import CloudIcon from '@mui/icons-material/Cloud';
import GavelIcon from '@mui/icons-material/Gavel';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LoopIcon from '@mui/icons-material/Loop';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import '../../theme/spaceTheme.css'; // Import the space theme CSS

// Icon mapping for service types
const iconMapping = {
  VerifiedUserIcon: <VerifiedUserIcon />,  // Cybersecurity Auditing
  BugReportIcon: <BugReportIcon />,        // Vulnerability Scanning
  SecurityIcon: <SecurityIcon />,          // Security as a Service (SECaaS)
  CloudIcon: <CloudIcon />,                // Cloud / IT Modernization
  GavelIcon: <GavelIcon />,                // Penetration Testing (Pentesting)
  AssessmentIcon: <AssessmentIcon />,      // Audit Preparation
  LoopIcon: <LoopIcon />,                  // Continuity of Operations
  SchoolIcon: <SchoolIcon />               // Cyber Awareness Training
};

// Helper function to generate service ID from title
const generateServiceId = (title) => {
  if (!title) return '';
  // Handle special case for SECaaS
  if (title.includes('SECaaS')) return 'service-secaas';
  // Handle special case for Cloud | IT Modernization
  if (title.includes('Cloud')) return 'service-cloud-modernization';
  // Handle special case for Penetration Testing
  if (title.includes('Penetration')) return 'service-pentesting';
  // Handle special case for Continuity of Operations
  if (title.includes('Continuity')) return 'service-continuity';
  // Handle special case for Cyber Awareness Training
  if (title.includes('Awareness')) return 'service-cyber-training';
  // Handle special case for Audit Preparation
  if (title.includes('Audit Preparation')) return 'service-audit-preparation';
  // Handle special case for Vulnerability Scanning
  if (title.includes('Vulnerability')) return 'service-vulnerability-scanning';
  // Handle special case for Cybersecurity Auditing
  if (title.includes('Cybersecurity Auditing')) return 'service-cybersecurity-auditing';
  
  // Default case: convert to lowercase, replace spaces with hyphens
  return 'service-' + title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
};

// Service card component styled like FeatureCards
const ServiceCard = ({ service }) => {
  // Generate service ID for anchor linking
  const serviceId = generateServiceId(service.title);
  
  // Create the link to the detailed blog section - use the same ID format as in services.json
  const detailLink = `#${serviceId}`;
  
  return (
    <Box
      display={'block'}
      width={1}
      height={1}
      sx={{
        textDecoration: 'none',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
        },
      }}
      id={serviceId}
    >
      <Box
        component={Card}
        padding={4}
        width={1}
        height={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
          <Box
            component={Avatar}
            width={60}
            height={60}
            marginBottom={3}
            bgcolor={alpha(colors.indigo[500], 0.1)}
            color={colors.indigo[500]}
            className="neon-text"
          >
            {iconMapping[service.icon]}
          </Box>
          <Typography
            variant={'h6'}
            gutterBottom
            sx={{ fontWeight: 600, mb: 2 }}
            className="neon-text"
          >
            {service.title}
          </Typography>
          <Typography sx={{ mb: 3 }} className="neon-text">
            {service.subtitle || 'NO SUBTITLE AVAILABLE'}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-start'} mt={2}>
          <Button
            component={RouterLink}
            to={detailLink}
            endIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
            size="large"
            sx={{ mt: 2 }}
          >
            Learn More
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

// Service section component
const ServiceSection = ({ services, heading, subtitle, description, hasDarkBackground = false, id }) => {
  return (
    <Box 
      bgcolor={hasDarkBackground ? 'alternate.main' : 'background.paper'} 
      py={6} 
      className={hasDarkBackground ? 'cyber-grid' : ''} 
      sx={{ 
        width: '100vw', 
        marginLeft: 'calc(-50vw + 50%)', 
        marginRight: 'calc(-50vw + 50%)' 
      }}
      id={id}
    >
      <Container>
        <Box marginBottom={6}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
              mb: 2,
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            className={hasDarkBackground ? 'neon-text' : ''}
          >
            {heading}
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
              mb: 3,
            }}
            className={hasDarkBackground ? 'neon-text-magenta' : ''}
          >
            {subtitle}
          </Typography>
          <Typography 
            variant="h6" 
            align={'center'} 
            color={'text.secondary'}
            sx={{ mb: 5 }}
          >
            {description}
          </Typography>
        </Box>

        <Grid container spacing={6}>
          {services && services.map((service, i) => (
            <Grid item xs={12} sm={6} md={3} key={i} data-aos="fade-up" data-aos-delay={i * 100}>
              <ServiceCard service={service} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const Services = () => {
  const [services, setServices] = useState([]);
  const location = useLocation();

  // Handle scrolling to the element when hash changes or component mounts
  useEffect(() => {
    // Get the hash from the URL (e.g., #service-secaas)
    const hash = location.hash;
    if (hash) {
      // Remove the # symbol
      const targetId = hash.substring(1);
      
      // Wait a bit for the DOM to fully render
      const scrollToElement = () => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          // Scroll to the element with a small offset for better visibility
          window.scrollTo({
            top: targetElement.offsetTop - 100, // 100px offset for better visibility
            behavior: 'smooth'
          });
          
          // Add a subtle highlight effect to draw attention
          targetElement.style.transition = 'box-shadow 0.3s ease-in-out';
          targetElement.style.boxShadow = '0 0 20px rgba(0, 255, 255, 0.7)'; // Cyan glow effect
          
          // Remove the highlight after a few seconds
          setTimeout(() => {
            targetElement.style.boxShadow = 'none';
          }, 2000);
          
          return true;
        }
        return false;
      };
      
      // Try immediately
      if (!scrollToElement()) {
        // If element not found, try again after DOM is fully loaded
        setTimeout(() => {
          if (!scrollToElement()) {
            // If still not found, try one more time with a longer delay
            setTimeout(scrollToElement, 1000);
          }
        }, 500);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    // Load services data and ensure subtitles are present
    const loadedServices = servicesData.services.map(service => ({
      ...service,
      subtitle: service.subtitle || 'Default subtitle for ' + service.title
    }));
    
    setServices(loadedServices);

    // AOS initialization moved to Page.js for consistency
    // Just refresh AOS when component mounts
    AOS.refresh();
    
    // Add dark-mode class to body for star background effect
    document.body.classList.add('dark-mode');
    
    // Handle hash navigation
    if (location.hash) {
      // Wait for DOM to be fully loaded
      setTimeout(() => {
        const targetId = location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        
        if (targetElement) {
          // Handled by ScrollToService component
        }
      }, 1000);
    }
  }, [location.hash]);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <ScrollToService />
      <Page>
        <ScrollToTop />
        <Topbar />
        <Box data-aos="fade-down">
          <HeroImageRight
            bgcolor={'alternate.main'}
            sx={{ paddingTop: '1em' }}
          />
        </Box>
        
        <Box data-aos="fade-up" className="cyber-grid">
          <ServiceSection
            services={services.slice(0, 4)}
            heading={'Managed IT and Cybersecurity Services'}
            subtitle={
              'Protect Your Business with IT Security, Compliance, and Cloud Solutions'
            }
            description={
              'Explore our range of managed IT services, cybersecurity solutions, and compliance offerings designed to safeguard your business.'
            }
            hasDarkBackground={false}
            id="primary-services"
          />
        </Box>
        
        <Box 
          data-aos="fade-left" 
          sx={{ py: 6 }}
        >
          <BlogWithLargeImage services={services.slice(0, 4)} idPrefix="detail-" />
        </Box>
        
        <Box data-aos="fade-right" className="cyber-grid">
          <ServiceSection
            services={services.slice(4, 8)}
            heading={
              'Empower Your Team with Cybersecurity and Compliance Training'
            }
            subtitle={
              'Strengthen Your IT Defense, Ensure Compliance, and Stay Prepared for Cyber Threats'
            }
            description={
              'Our training programs and compliance solutions help your team recognize and respond to security threats effectively.'
            }
            hasDarkBackground={false}
            id="secondary-services"
          />
        </Box>

        <Box 
          data-aos="fade-left" 
          sx={{ py: 6 }}
        >
          <BlogWithLargeImage services={services.slice(4, 8)} idPrefix="detail-" />
        </Box>
        
        <Box className="cyber-grid" sx={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)', marginRight: 'calc(-50vw + 50%)' }}>
          <Container>
            <Box data-aos="fade-up" sx={{ py: 6 }}>
            </Box>
            <Box data-aos="fade-up" sx={{ pb: 6 }}>
              <Form />
            </Box>
          </Container>
        </Box>
        
        <Footer />
      </Page>
    </div>
  );
};

export default Services;
