import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';

const coreValues = [
  {
    title: 'Accessible Security',
    subtitle:
      'Cybersecurity shouldn\'t be exclusive. We believe every business—no matter the size—deserves access to enterprise-grade protection without the enterprise price tag.',
    icon: <AccessibilityNewIcon fontSize="large" />,
  },
  {
    title: 'Clarity Over Complexity',
    subtitle:
      'Security doesn\'t have to be confusing. We cut through the noise to deliver clear, practical solutions tailored to your business needs.',
    icon: <LightbulbIcon fontSize="large" />,
  },
  {
    title: 'Innovate Through Automation',
    subtitle:
      'We challenge the status quo. By harnessing automation, AI, and modern tools, we deliver smarter, more efficient security services—without bloated costs.',
    icon: <AutoAwesomeIcon fontSize="large" />,
  },
  {
    title: 'Compliance, Simplified',
    subtitle:
      'Regulations and insurance requirements? We\'ve got you. We help you hit the mark without the headache, with proactive, budget-conscious strategies.',
    icon: <VerifiedUserIcon fontSize="large" />,
  },
  {
    title: 'Community-Focused Protection',
    subtitle:
      'Small businesses power communities. We\'re here to protect them so they can keep doing what they do best—serving, innovating, and growing.',
    icon: <PeopleIcon fontSize="large" />,
  },
  {
    title: 'Trust Through Transparency',
    subtitle:
      'No black boxes, no jargon. Just clear communication, honest advice, and a partner you can count on to keep your business secure.',
    icon: <VisibilityIcon fontSize="large" />,
  },
];

const CoreValues = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
              paddingBottom: '1em',
            }}
            gutterBottom
            color={'primary'}
            align={'center'}
            className="neon-text"
            data-aos="fade-up"
          >
            Our Guiding Principles
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
              paddingBottom: '1em',
            }}
            className="neon-text"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            Our Core Values at Nebula Networks
          </Typography>
          <Typography
            variant="h6"
            align={'center'}
            color={'text.secondary'}
            paddingBottom={4}
            data-aos="fade-up"
            data-aos-delay={200}
          >
            These values guide everything we do—from how we build our solutions to how we interact with our clients.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {coreValues.map((item, i) => (
            <Grid 
              key={i} 
              item 
              xs={12} 
              md={6} 
              lg={4}
              data-aos="fade-up"
              data-aos-delay={300 + (i * 100)}
            >
              <ListItem
                component="div"
                disableGutters
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 2,
                  height: '100%',
                  transition: 'all 0.3s ease-in-out',
                  borderRadius: 2,
                  border: '1px solid rgba(0, 229, 255, 0.1)',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 0 20px rgba(0, 229, 255, 0.2)',
                    border: '1px solid rgba(0, 229, 255, 0.3)',
                  },
                }}
              >
                <Box
                  component={ListItemAvatar}
                  marginBottom={2}
                  minWidth={'auto !important'}
                >
                  <Box 
                    color={theme.palette.primary.main}
                    sx={{
                      backgroundColor: 'rgba(0, 229, 255, 0.1)',
                      borderRadius: '50%',
                      padding: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </Box>
                </Box>
                <ListItemText
                  primary={item.title}
                  secondary={item.subtitle}
                  primaryTypographyProps={{
                    variant: 'h6',
                    gutterBottom: true,
                    align: 'center',
                    className: 'neon-text',
                  }}
                  secondaryTypographyProps={{ 
                    align: 'center',
                    variant: 'body1',
                  }}
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontWeight: 700,
                    },
                    margin: 0,
                  }}
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CoreValues;
