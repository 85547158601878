import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '../../components/Container';
import Page from 'components/Page';
import { Topbar, Footer } from 'layouts/Fixed/components';
import { Link as RouterLink } from 'react-router-dom';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../../theme/spaceTheme.css';

const NotFound = () => {

  useEffect(() => {
    // Add dark-mode class to body for star background effect
    document.body.classList.add('dark-mode');
    // Initialize AOS animations
    AOS.init({
      once: true,
      delay: 50,
      duration: 1000,
      offset: 100,
    });
  }, []);

  return (
    <Page>
      <Topbar />
      <Box
        position={'relative'}
        minHeight={'calc(100vh - 247px)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          background: 'linear-gradient(135deg, #000428 0%, #004e92 100%)',
          overflow: 'hidden',
        }}
      >
        {/* Animated stars in background */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        >
          {/* Floating debris elements */}
          {[...Array(20)].map((_, index) => (
            <Box
              key={`star-${index}`}
              sx={{
                position: 'absolute',
                width: Math.random() * 3 + 1,
                height: Math.random() * 3 + 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '50%',
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `twinkle ${Math.random() * 5 + 3}s infinite ${Math.random() * 5}s`,
                boxShadow: '0 0 3px rgba(255, 255, 255, 0.8)',
                '@keyframes twinkle': {
                  '0%': { opacity: 0.2, transform: 'scale(0.8)' },
                  '50%': { opacity: 1, transform: 'scale(1.2)' },
                  '100%': { opacity: 0.2, transform: 'scale(0.8)' },
                },
              }}
            />
          ))}
          
          {/* Floating satellite */}
          <Box
            sx={{
              position: 'absolute',
              top: '15%',
              right: '10%',
              animation: 'float 15s infinite ease-in-out',
              '@keyframes float': {
                '0%': { transform: 'translateY(0px) rotate(0deg)' },
                '50%': { transform: 'translateY(20px) rotate(5deg)' },
                '100%': { transform: 'translateY(0px) rotate(0deg)' },
              },
            }}
            data-aos="fade-left"
          >
            <SatelliteAltIcon 
              sx={{ 
                fontSize: 60, 
                color: 'rgba(0, 229, 255, 0.9)',
                filter: 'drop-shadow(0 0 10px rgba(0, 229, 255, 0.7))'
              }} 
            />
          </Box>
        </Box>

        {/* Horizontal line accent */}
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: 0,
            width: '100%',
            height: '1px',
            background: 'linear-gradient(90deg, rgba(0,229,255,0) 0%, rgba(0,229,255,0.5) 50%, rgba(0,229,255,0) 100%)',
            boxShadow: '0 0 10px rgba(0, 229, 255, 0.5)',
            zIndex: 1,
          }}
        />

        <Container sx={{ position: 'relative', zIndex: 2 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: { xs: 'center', md: 'left' } }}
              data-aos="fade-right"
            >
              <Typography
                variant="h1"
                component="h1"
                className="neon-text"
                sx={{
                  fontWeight: 900,
                  fontSize: { xs: '6rem', sm: '8rem', md: '10rem' },
                  textShadow: '0 0 20px rgba(0, 229, 255, 0.8), 0 0 30px rgba(0, 229, 255, 0.6)',
                  marginBottom: 2,
                }}
              >
                404
              </Typography>
              <Typography
                variant="h4"
                component="p"
                className="neon-text-magenta"
                sx={{ 
                  marginBottom: 3,
                  fontWeight: 500,
                  textShadow: '0 0 10px rgba(255, 0, 255, 0.6)'
                }}
              >
                COSMIC ANOMALY DETECTED
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.8)',
                  marginBottom: 4,
                  maxWidth: { md: '80%' }
                }}
              >
                The space coordinates you're looking for seem to have drifted into a black hole. Our navigation systems can't locate this destination.
              </Typography>
              <Button
                component={RouterLink}
                variant="contained"
                color="primary"
                size="large"
                to={'/'}
                startIcon={<RocketLaunchIcon />}
                sx={{
                  mt: 2,
                  background: 'linear-gradient(90deg, #1E88E5 0%, #00BFA5 100%)',
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: '-100%',
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(90deg, transparent, rgba(0,229,255,0.3), transparent)',
                    transition: 'all 0.6s ease',
                  },
                  '&:hover': {
                    boxShadow: '0 0 15px rgba(0, 255, 255, 0.7)',
                    background: 'linear-gradient(90deg, #1976D2 0%, #00A896 100%)',
                    '&::before': {
                      left: '100%',
                    },
                  }
                }}
              >
                Return to Base
              </Button>
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-left">
              <Box
                sx={{
                  position: 'relative',
                  height: { xs: '300px', md: '400px' },
                  width: '100%',
                }}
              >
                {/* Space ship lost in space */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    animation: 'drift 8s infinite ease-in-out',
                    '@keyframes drift': {
                      '0%': { transform: 'translate(-50%, -50%) rotate(5deg)' },
                      '50%': { transform: 'translate(-60%, -40%) rotate(-5deg)' },
                      '100%': { transform: 'translate(-50%, -50%) rotate(5deg)' },
                    },
                  }}
                >
                  {/* Outer orbit ring */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '200px',
                      height: '200px',
                      borderRadius: '50%',
                      border: '1px solid rgba(0, 229, 255, 0.3)',
                      animation: 'rotate 20s linear infinite',
                      '@keyframes rotate': {
                        '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
                        '100%': { transform: 'translate(-50%, -50%) rotate(360deg)' },
                      },
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: '10%',
                        left: '50%',
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(0, 229, 255, 0.8)',
                        boxShadow: '0 0 15px rgba(0, 229, 255, 0.8)',
                      },
                    }}
                  />
                  
                  {/* Inner orbit ring with warning symbols */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '120px',
                      height: '120px',
                      borderRadius: '50%',
                      border: '1px solid rgba(255, 0, 255, 0.3)',
                      animation: 'rotate 15s linear infinite reverse',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: '80%',
                        left: '30%',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255, 0, 255, 0.8)',
                        boxShadow: '0 0 15px rgba(255, 0, 255, 0.8)',
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: '30%',
                        right: '20%',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255, 0, 255, 0.8)',
                        boxShadow: '0 0 15px rgba(255, 0, 255, 0.8)',
                      },
                    }}
                  />
                  
                  {/* Broken spacecraft */}
                  <Box
                    sx={{
                      position: 'relative',
                      width: '80px',
                      height: '80px',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid rgba(255, 0, 0, 0.5)',
                      boxShadow: '0 0 30px rgba(255, 0, 0, 0.3)',
                      overflow: 'hidden',
                      animation: 'pulse 2s infinite',
                      '@keyframes pulse': {
                        '0%': { boxShadow: '0 0 30px rgba(255, 0, 0, 0.3)' },
                        '50%': { boxShadow: '0 0 40px rgba(255, 0, 0, 0.5)' },
                        '100%': { boxShadow: '0 0 30px rgba(255, 0, 0, 0.3)' },
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'radial-gradient(circle, rgba(255,0,0,0.1) 0%, rgba(0,0,0,0) 70%)',
                      },
                    }}
                  >
                    <Typography 
                      variant="h2" 
                      component="div"
                      sx={{ 
                        color: 'rgba(255, 0, 0, 0.8)',
                        fontWeight: 'bold',
                        textShadow: '0 0 10px rgba(255, 0, 0, 0.8)',
                        animation: 'blink 1.5s infinite',
                        '@keyframes blink': {
                          '0%': { opacity: 0.7 },
                          '50%': { opacity: 1 },
                          '100%': { opacity: 0.7 },
                        },
                      }}
                    >
                      !
                    </Typography>
                  </Box>
                </Box>
                
                {/* Floating error message */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '10%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    padding: '10px 20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    border: '1px solid rgba(255, 0, 0, 0.5)',
                    borderRadius: '5px',
                    animation: 'flicker 4s infinite',
                    '@keyframes flicker': {
                      '0%': { opacity: 0.7 },
                      '5%': { opacity: 0.5 },
                      '10%': { opacity: 0.7 },
                      '15%': { opacity: 0.8 },
                      '20%': { opacity: 0.6 },
                      '25%': { opacity: 0.9 },
                      '30%': { opacity: 0.7 },
                      '100%': { opacity: 0.7 },
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ 
                      color: 'rgba(255, 0, 0, 0.9)',
                      fontFamily: 'monospace',
                    }}
                  >
                    ERROR: PAGE_NOT_FOUND
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Page>
  );
};

export default NotFound;
