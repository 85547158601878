import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Page from '../../components/Page';
import Container from '../../components/Container';
import '../../theme/spaceTheme.css'; // Import the space theme CSS
import { Hero, Story } from './components';
import { Topbar, Footer } from 'layouts/Fixed/components';
import aboutData from '../../assets/about.json';
import WithSimpleCards from 'components/WithSimpleCards';
import WithCountUpNumbersAndCoverImage from 'components/WithCountUpNumbersAndCoverImage';
import Form from 'components/Form';
import ScrollToTop from 'components/ScrollToTop';
import FeaturesWithSimpleIcons from 'components/FeaturesWithSimpleIcons';
import CoreValues from 'components/CoreValues';
import { Box } from '@mui/material';

const About = () => {
  const { mission, team } = aboutData;

  useEffect(() => {
    // AOS initialization moved to Page.js for consistency
    // Just refresh AOS when component mounts
    AOS.refresh();
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Page>
        <ScrollToTop />
        <Topbar />
        <Hero />
        <Container maxWidth={800} paddingY={'0 !important'}>
          <Divider />
        </Container>
        <Box className="cyber-grid">
          <Container data-aos="fade-up">
            <Story title={mission.title} description={mission.description} />
          </Container>
        </Box>
        <Box bgcolor={'alternate.main'}>
          <Container data-aos="fade-left">
            <WithSimpleCards team={team} />
          </Container>
        </Box>
        <Box className="cyber-grid">
          <Container data-aos="fade-up">
            <CoreValues />
          </Container>
        </Box>
        <Box bgcolor={'alternate.main'}>
          <Container data-aos="fade-right">
            <FeaturesWithSimpleIcons />
          </Container>
        </Box>
        <Box className="cyber-grid">
          <Container data-aos="fade-left">
            <WithCountUpNumbersAndCoverImage />
          </Container>
        </Box>
        <Container maxWidth={800} paddingY={'0 !important'}>
          <Divider />
        </Container>
        <Box bgcolor={'alternate.main'}>
          <Container data-aos="fade-up">
            <Form />
          </Container>
        </Box>
        <Footer />
      </Page>
    </div>
  );
};

export default About;
