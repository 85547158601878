/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AOS from 'aos';

import Container from 'components/Container';
import 'theme/spaceTheme.css'; // Import space theme CSS

// Helper function to generate service ID from title - EXACTLY matching services.json
const generateServiceId = (title, prefix = '') => {
  if (!title) return '';
  // Handle special case for SECaaS
  if (title.includes('SECaaS')) return prefix + 'service-secaas';
  // Handle special case for Cloud | IT Modernization
  if (title.includes('Cloud')) return prefix + 'service-cloud-modernization';
  // Handle special case for Penetration Testing
  if (title.includes('Penetration')) return prefix + 'service-pentesting';
  // Handle special case for Continuity of Operations
  if (title.includes('Continuity')) return prefix + 'service-continuity';
  // Handle special case for Cyber Awareness Training
  if (title.includes('Awareness')) return prefix + 'service-cyber-training';
  // Handle special case for Audit Preparation
  if (title.includes('Audit Preparation')) return prefix + 'service-audit-preparation';
  // Handle special case for Vulnerability Scanning
  if (title.includes('Vulnerability')) return prefix + 'service-vulnerability-scanning';
  // Handle special case for Cybersecurity Auditing
  if (title.includes('Cybersecurity Auditing')) return prefix + 'service-cybersecurity-auditing';
  
  // Default case: convert to lowercase, replace spaces with hyphens
  return prefix + 'service-' + title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
};

// Helper function to provide business-focused explanations for each service
const getBusinessBenefit = (title) => {
  // Match the service title to provide specific business benefits
  if (title.includes('Cybersecurity Auditing')) {
    return 'Without proper security audits, your business could face costly data breaches averaging $4.35 million per incident. Regular audits help identify vulnerabilities before hackers do, protect your reputation, and demonstrate to clients that you take their data security seriously.';
  }
  if (title.includes('Vulnerability Scanning')) {
    return 'New cybersecurity threats emerge daily. Without regular scanning, your business is an easy target for hackers. Our scanning service provides peace of mind by continuously monitoring your systems, helping you avoid the downtime, financial losses, and reputation damage that come with security breaches.';
  }
  if (title.includes('SECaaS')) {
    return 'Hiring an in-house cybersecurity team costs an average of $150,000+ annually per security expert. Our Security as a Service provides enterprise-level protection at a fraction of the cost, with 24/7 monitoring and expertise that would be impossible to maintain internally for most small and medium businesses.';
  }
  if (title.includes('Cloud') || title.includes('IT Modernization')) {
    return 'Outdated IT systems cost businesses 47% more in maintenance than modern cloud solutions. By modernizing your infrastructure, you\'ll reduce IT costs, enable remote work capabilities, scale your business more easily, and gain competitive advantages through improved performance and reliability.';
  }
  if (title.includes('Penetration Testing')) {
    return 'Even with security measures in place, skilled attackers can find ways into your systems. Penetration testing simulates real attacks to find weaknesses before hackers do. This proactive approach helps you avoid data breaches that could cost millions in damages, regulatory fines, and lost business opportunities.';
  }
  if (title.includes('Audit Preparation')) {
    return 'Failed compliance audits can result in fines up to $50,000 per violation for standards like HIPAA or PCI DSS. Our audit preparation services ensure you meet industry requirements, avoid penalties, maintain business relationships that require compliance certifications, and build trust with your customers.';
  }
  if (title.includes('Continuity')) {
    return 'Businesses without continuity plans suffer an average of 8 hours of downtime per incident, with costs ranging from $10,000 to $5 million per hour depending on your industry. Our continuity services ensure your business can keep operating during disruptions, protecting your revenue, customer relationships, and reputation.';
  }
  if (title.includes('Awareness') || title.includes('Training')) {
    return '95% of cybersecurity breaches are caused by human error. Training your team is the most cost-effective security investment you can make. Our training programs transform your employees from your biggest security vulnerability into your strongest defense against increasingly sophisticated cyber attacks.';
  }
  
  // Default case
  return 'This service helps protect your business from costly cybersecurity incidents, ensures regulatory compliance, and provides peace of mind so you can focus on growing your business rather than worrying about IT security threats.';
};

// ServiceCard component for individual service items
const ServiceCard = ({ item, index, idPrefix }) => {
  const theme = useTheme();
  const serviceId = generateServiceId(item.title, idPrefix);
  const isEven = index % 2 === 0;
  
  // Calculate animation delay based on index
  const animationDelay = (index % 4) * 100;
  
  return (
    <Grid 
      item 
      xs={12}
      sx={{ marginBottom: 4 }}
      id={serviceId}
      data-aos={isEven ? 'fade-left' : 'fade-right'}
      data-aos-delay={animationDelay}
      data-aos-duration="800"
    >
      <Box
        component={Card}
        width={1}
        height={1}
        borderRadius={0}
        boxShadow={0}
        display={'flex'}
        flexDirection={{
          xs: 'column',
          md: isEven ? 'row-reverse' : 'row',
        }}

        sx={{ 
          backgroundImage: 'none', 
          bgcolor: 'transparent',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 0 15px rgba(0, 229, 255, 0.7)', // Enhanced cyan glow effect on hover using the site's color
            transform: 'translateY(-5px)' // Slight lift effect on hover
          }
        }}
      >
        <Box
          sx={{
            width: { xs: 1, md: '50%' },
          }}
        >
          <Box
            component={'img'}
            height={1}
            width={1}
            src={item.image}
            alt={item.title}
            sx={{
              objectFit: 'cover',
              maxHeight: 360,
              filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              transition: 'transform 0.5s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)' // Subtle zoom effect on hover
              }
            }}
          />
        </Box>
        <CardContent
          sx={{
            paddingX: { xs: 1, sm: 2, md: 4 },
            paddingY: { xs: 2, sm: 4 },
            width: { xs: 1, md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.05)', // Subtle background for better readability
            borderLeft: isEven ? 'none' : '1px solid rgba(0, 191, 165, 0.2)',
            borderRight: isEven ? '1px solid rgba(0, 191, 165, 0.2)' : 'none'
          }}
        >
          <Typography
            variant={'h6'}
            fontWeight={700}
            sx={{ textTransform: 'uppercase' }}
            className="neon-text" // Space theme neon text effect
          >
            {item.title}
          </Typography>
          <Typography color="text.secondary" marginTop={1}>
            {item.content}
          </Typography>
          
          {/* Business benefits section with staggered animation */}
          <Box 
            marginTop={3} 
            padding={2} 
            bgcolor={'rgba(0,0,0,0.03)'} 
            borderRadius={1}
            border={'1px solid rgba(0, 191, 165, 0.2)'}
            data-aos="fade-up"
            data-aos-delay={animationDelay + 200}

            sx={{
              transition: 'box-shadow 0.3s ease-in-out',
              '&:hover': {
                boxShadow: '0 0 10px rgba(0, 229, 255, 0.3)' // Cyan glow on hover using the site's color
              }
            }}
          >
            <Typography 
              variant="subtitle1" 
              fontWeight={600} 
              className="neon-text" 
              sx={{ mb: 1 }}
            >
              How we can help:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {getBusinessBenefit(item.title)}
            </Typography>
          </Box>
        </CardContent>
      </Box>
    </Grid>
  );
};

const BlogWithLargeImage = ({ services, idPrefix = '' }) => {
  // Refresh AOS when component mounts
  useEffect(() => {
    AOS.refresh();
  }, []);

  return (
    <Box bgcolor={'alternate.main'}>
      <Container>
        <Grid container spacing={4}>
          {services.map((item, i) => (
            <ServiceCard 
              key={i} 
              item={item} 
              index={i} 
              idPrefix={idPrefix} 
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default BlogWithLargeImage;
