import React from 'react';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Container from 'components/Container';

// Import space theme styling
import 'theme/spaceTheme.css';

const WithSimpleCards = ({ team }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        paddingY: 4, // Add vertical padding
      }}
    >
      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography 
              fontWeight={700} 
              variant={'h3'} 
              align={'center'}
              className="neon-text"
              sx={{
                textShadow: '0 0 10px rgba(0, 255, 255, 0.7)',
                mb: 1
              }}
            >
              The Team
            </Typography>
            <Typography 
              variant={'subtitle1'} 
              align={'center'}
              className="neon-text-magenta"
              sx={{
                maxWidth: '800px',
                margin: '0 auto',
                mb: 3
              }}
            >
              Meet the experts behind Nebula Networks
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {team.map((member, i) => (
              <Grid item xs={12} sm={6} md={6} lg={6} key={i} data-aos="fade-up" data-aos-delay={i * 100}>
                <Box
                  component={Card}
                  boxShadow={2}
                  sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column', // Always column for consistent layout
                    alignItems: 'center',
                    padding: theme.spacing(3), // Increased padding inside the card
                    borderRadius: theme.spacing(1), // Optional: Slightly rounded corners
                    transition: 'all .3s ease-in-out',
                    position: 'relative',
                    height: '100%', // Make all cards the same height
                    background: 'transparent',
                    border: '1px solid rgba(30, 136, 229, 0.2)',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: theme.spacing(1),
                      padding: '1px',
                      background: 'linear-gradient(90deg, rgba(0, 191, 165, 0.3), rgba(30, 136, 229, 0.3))',
                      mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                      maskComposite: 'exclude',
                      pointerEvents: 'none',
                    },
                    '&:hover': {
                      transform: `translateY(-${theme.spacing(1 / 2)})`,
                      boxShadow: '0 0 20px rgba(0, 229, 255, 0.2)',
                      border: '1px solid rgba(0, 229, 255, 0.3)',
                      '&::before': {
                        background: 'linear-gradient(90deg, rgba(0, 255, 255, 0.5), rgba(255, 0, 255, 0.5))',
                      }
                    },
                  }}
                >
                  <Avatar
                    src={member.avatar}
                    alt={`${member.name} - ${member.title}`} // Descriptive alt text for accessibility
                    sx={{
                      height: 100,
                      width: 100,
                      marginBottom: theme.spacing(2),
                      border: '2px solid rgba(0, 255, 255, 0.3)',
                      boxShadow: '0 0 10px rgba(0, 255, 255, 0.2)',
                      transition: 'all .3s ease-in-out',
                      backgroundColor: 'rgba(0, 229, 255, 0.1)',
                      '&:hover': {
                        boxShadow: '0 0 15px rgba(0, 255, 255, 0.5)',
                        transform: 'scale(1.1)',
                      }
                    }}
                  />
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <ListItemText 
                      primary={
                        <Typography 
                          variant="h6" 
                          className="neon-text"
                          sx={{ 
                            fontWeight: 600,
                            textShadow: '0 0 5px rgba(0, 255, 255, 0.5)' 
                          }}
                        >
                          {member.name}
                        </Typography>
                      } 
                      secondary={
                        <Typography 
                          variant="subtitle2" 
                          className="neon-text-magenta"
                          sx={{ opacity: 0.9 }}
                        >
                          {member.title}
                        </Typography>
                      } 
                    />
                    <Typography 
                      variant={'subtitle2'} 
                      color={'text.secondary'} 
                      marginTop={1}
                      sx={{
                        color: alpha(theme.palette.common.white, 0.7),
                        lineHeight: 1.6
                      }}
                    >
                      {member.description}
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default WithSimpleCards;
