/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import Container from 'components/Container';
import AOS from 'aos';
import 'theme/spaceTheme.css';

const mock = [
  {
    title: '40',
    subtitle: 'Over 40 years of combined industry experience, providing expert IT strategies to safeguard businesses from evolving threats.',
    suffix: '+',
    start: 5,
  },
  {
    title: '8400',
    subtitle: 'More than 8,400 devices secured, offering proven expertise in protecting complex IT environments.',
    suffix: '+',
    start: 1000,
    formatterFn: (value) => `${value.toLocaleString()}`,
  },
  {
    title: '100',
    subtitle: '100% of our customers report improved security and peace of mind with our tailored IT and cybersecurity solutions.',
    suffix: '%',
    start: 10,
  },
];


const WithCountUpNumbersAndCoverImage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // Refresh AOS when component mounts
  useEffect(() => {
    AOS.refresh();
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true, // Count up only once when in view
    threshold: 0.2,    // Trigger when 20% of the box is visible
  });

  return (
    <Box ref={ref}>
      <Container>
        <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
          <Grid item xs={12} md={6}>
            <Box marginBottom={4}>
              <Typography 
                sx={{ fontWeight: 700 }} 
                variant={'h4'} 
                gutterBottom
                className="neon-text"
                data-aos="fade-up"
              >
                Your Trusted IT Partner
              </Typography>
              <Typography 
                variant={'h6'} 
                component={'p'} 
                color={'text.secondary'}
                data-aos="fade-up"
                data-aos-delay={100}
              >
                Empowering businesses through expert IT solutions and leading
                edge cybersecurity, led by industry pioneers.
              </Typography>
            </Box>
            <Grid container spacing={3}>
              {mock.map((item, i) => (
                <Grid 
                  key={i} 
                  item 
                  xs={12} 
                  md={4}
                  data-aos="fade-up"
                  data-aos-delay={200 + (i * 100)}
                  sx={{ display: 'flex' }}
                >
                  <Box
                    sx={{
                      padding: '24px 16px',
                      borderRadius: 2,
                      transition: 'all 0.3s ease-in-out',
                      border: '1px solid rgba(0, 229, 255, 0.1)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: '0 0 20px rgba(0, 229, 255, 0.2)',
                        border: '1px solid rgba(0, 229, 255, 0.3)',
                      },
                    }}
                  >
                    <Typography 
                      variant="h4" 
                      color={'primary'} 
                      gutterBottom
                      className="neon-text"
                      sx={{
                        fontWeight: 700,
                        textAlign: 'center',
                        marginBottom: 3,
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-word',
                        minHeight: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {inView ? (
                        <CountUp
                          start={0}
                          end={parseFloat(item.title)}
                          duration={2.5}
                          suffix={item.suffix}
                          formattingFn={item.formatterFn}
                          style={{ display: 'inline-block' }}
                        />
                      ) : (
                        '0'
                      )}
                    </Typography>
                    <Typography 
                      color="text.secondary" 
                      component="p"
                      sx={{ 
                        textAlign: 'center',
                        fontSize: '0.875rem',
                        lineHeight: 1.6
                      }}
                    >
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            md={6}
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box 
              component={Card} 
              boxShadow={4} 
              height={1} 
              width={1}
              data-aos="fade-left"
              data-aos-delay={150}
              sx={{
                overflow: 'hidden',
                borderRadius: 2,
                border: '1px solid rgba(0, 229, 255, 0.3)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 0 30px rgba(0, 229, 255, 0.4)',
                  transform: 'scale(1.02)',
                },
              }}
            >
              <Box
                component={CardMedia}
                height={1}
                width={1}
                minHeight={300}
                image="https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=1472&auto=format&fit=crop"
                sx={{
                  transition: 'transform 0.5s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WithCountUpNumbersAndCoverImage;
