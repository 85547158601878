import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import NotFound from 'pages/NotFound/NotFound';
import Service from 'pages/Service';
import About from 'pages/About';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

// Create the router
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <App />
      </>
    ),
    errorElement: <NotFound />,
  },
  {
    path: '/services',
    element: (
      <>
        <Service />
      </>
    ),
    errorElement: <NotFound />,
  },
  {
    path: '/service',
    element: (
      <>
        <Service />
      </>
    ),
    errorElement: <NotFound />,
  },
  {
    path: '/about',
    element: (
      <>
        <About />
      </>
    ),
    errorElement: <NotFound />,
  },
]);

// Render the root component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <Analytics />
    <SpeedInsights />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
