import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';
import AOS from 'aos';
import 'theme/spaceTheme.css';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const Hero = () => {
  useEffect(() => {
    // Refresh AOS animations when component mounts
    AOS.refresh();

    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  }, []);

  return (
    <Box
      position={'relative'}
      minHeight={{ xs: 450, sm: 500, md: 600 }}
      display={'flex'}
      alignItems={'center'}
      sx={{
        marginTop: '80px',
        background: 'linear-gradient(135deg, #000428 0%, #004e92 100%)',
        overflow: 'hidden',
      }}
    >


      {/* Horizontal line accent - exact same as Services page */}
      <Box
        sx={{
          position: 'absolute',
          top: '15%',
          left: 0,
          width: '100%',
          height: '1px',
          background: 'linear-gradient(90deg, rgba(0,229,255,0) 0%, rgba(0,229,255,0.5) 50%, rgba(0,229,255,0) 100%)',
          boxShadow: '0 0 10px rgba(0, 229, 255, 0.5)',
          zIndex: 1,
        }}
      />

      <Container position={'relative'} zIndex={3}>
        <Grid container spacing={4} alignItems={'center'}>
          <Grid item xs={12} md={7} data-aos="fade-right">
            <Box>
              <Typography
                variant="h2"
                gutterBottom
                className="neon-text"
                data-aos="fade-up"
                data-aos-delay={50}
                sx={{
                  fontWeight: 900,
                  color: 'common.white',
                  textTransform: 'uppercase',
                  textShadow: '0 0 20px rgba(0, 229, 255, 0.8), 0 0 30px rgba(0, 229, 255, 0.6)',
                  position: 'relative',
                  marginBottom: 3,
                }}
              >
                About Nebula Networks
              </Typography>
              <Typography
                variant="h6"
                component="p"
                data-aos="fade-up"
                data-aos-delay={100}
                sx={{
                  color: 'common.white',
                  maxWidth: '600px',
                  marginBottom: 4,
                  lineHeight: 1.6,
                }}
              >
                Founded by industry experts with a focus on Security and Innovation in the Digital Space.
                We bring enterprise-grade solutions to businesses of all sizes.
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2,
                  marginTop: 4,
                }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    borderRadius: '30px',
                    border: '1px solid rgba(0, 229, 255, 0.3)',
                    backgroundColor: 'rgba(0, 229, 255, 0.1)',
                    color: 'white',
                    fontWeight: 'medium',
                    fontSize: '0.875rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    backdropFilter: 'blur(8px)',
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: 'rgba(0, 229, 255, 0.8)',
                      boxShadow: '0 0 10px rgba(0, 229, 255, 0.8)',
                    }}
                  />
                  Global Reach
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    borderRadius: '30px',
                    border: '1px solid rgba(255, 0, 255, 0.3)',
                    backgroundColor: 'rgba(255, 0, 255, 0.1)',
                    color: 'white',
                    fontWeight: 'medium',
                    fontSize: '0.875rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    backdropFilter: 'blur(8px)',
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: 'rgba(255, 0, 255, 0.8)',
                      boxShadow: '0 0 10px rgba(255, 0, 255, 0.8)',
                    }}
                  />
                  Industry Leaders
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              alignItems: 'center',
            }}
            data-aos="fade-left"
          >
            <Box
              sx={{
                position: 'relative',
                width: '350px',
                height: '350px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* Orbital rings */}
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  border: '1px solid rgba(0, 229, 255, 0.2)',
                  animation: 'rotate 20s linear infinite',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '10%',
                    left: '50%',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.8)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.8)',
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '20%',
                    right: '15%',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 191, 165, 0.8)', /* Secondary teal color */
                    boxShadow: '0 0 12px rgba(0, 191, 165, 0.8)',
                  },
                  '@keyframes rotate': {
                    '0%': { transform: 'rotate(0deg)' },
                    '100%': { transform: 'rotate(360deg)' },
                  },
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  width: '80%',
                  height: '80%',
                  borderRadius: '50%',
                  border: '1px solid rgba(255, 0, 255, 0.2)',
                  animation: 'rotate 15s linear infinite reverse',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '80%',
                    left: '30%',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(255, 0, 255, 0.8)',
                    boxShadow: '0 0 15px rgba(255, 0, 255, 0.8)',
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: '30%',
                    right: '60%',
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(30, 136, 229, 0.8)', /* Primary blue color */
                    boxShadow: '0 0 12px rgba(30, 136, 229, 0.8)',
                  },
                }}
              />
              {/* Additional middle ring with dots */}
              <Box
                sx={{
                  position: 'absolute',
                  width: '60%',
                  height: '60%',
                  borderRadius: '50%',
                  border: '1px solid rgba(30, 136, 229, 0.2)',
                  animation: 'rotate 25s linear infinite',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '10%',
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', /* White dot */
                    boxShadow: '0 0 10px rgba(255, 255, 255, 0.8)',
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '10%',
                    right: '40%',
                    width: '4px',
                    height: '4px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(255, 193, 7, 0.8)', /* Amber/gold accent */
                    boxShadow: '0 0 8px rgba(255, 193, 7, 0.8)',
                  },
                }}
              />

              {/* Central icon */}
              <Box
                sx={{
                  width: '120px',
                  height: '120px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  border: '1px solid rgba(0, 229, 255, 0.3)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0 0 30px rgba(0, 229, 255, 0.3)',
                  position: 'relative',
                  overflow: 'hidden',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle, rgba(0,229,255,0.1) 0%, rgba(0,0,0,0) 70%)',
                  },
                }}
              >
                <RocketLaunchIcon
                  sx={{
                    fontSize: 60,
                    color: 'rgba(0, 229, 255, 0.9)',
                    transform: 'rotate(-30deg)',
                    animation: 'rocketPulse 3s infinite',
                    '@keyframes rocketPulse': {
                      '0%': { opacity: 0.8, transform: 'rotate(-30deg) scale(0.95)' },
                      '50%': { opacity: 1, transform: 'rotate(-30deg) scale(1.05)' },
                      '100%': { opacity: 0.8, transform: 'rotate(-30deg) scale(0.95)' },
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
