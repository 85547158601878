import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';
import '../../../src/theme/spaceTheme.css';

const HeroImageRight = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      position={'relative'}
      minHeight={{ xs: 450, sm: 500, md: 600 }}
      display={'flex'}
      alignItems={'center'}
      sx={{
        marginTop: '80px',
        background: 'linear-gradient(135deg, #000428 0%, #004e92 100%)',
        overflow: 'hidden',
      }}
    >


      {/* Horizontal line accent */}
      <Box
        sx={{
          position: 'absolute',
          top: '15%',
          left: 0,
          width: '100%',
          height: '1px',
          background: 'linear-gradient(90deg, rgba(0,229,255,0) 0%, rgba(0,229,255,0.5) 50%, rgba(0,229,255,0) 100%)',
          boxShadow: '0 0 10px rgba(0, 229, 255, 0.5)',
          zIndex: 1,
        }}
      />

      <Container position={'relative'} zIndex={3}>
        <Grid container spacing={6} alignItems={'center'}>
          <Grid item xs={12} md={6} data-aos="fade-right">
            <Box>
              <Typography
                variant="h2"
                className="neon-text"
                sx={{ 
                  fontWeight: 700,
                  mb: 3,
                  lineHeight: 1.3,
                  color: 'white',
                  textShadow: '0 0 20px rgba(0, 229, 255, 0.8), 0 0 30px rgba(0, 229, 255, 0.6)',
                }}
                data-aos="fade-up"
              >
                Strengthen your IT Infrastructure
                <Typography
                  component={'span'}
                  variant={'inherit'}
                  className="neon-text-magenta"
                  sx={{ 
                    display: 'block',
                    mt: 1,
                    textShadow: '0 0 20px rgba(255, 0, 255, 0.8), 0 0 30px rgba(255, 0, 255, 0.6)',
                  }}
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  & Security
                </Typography>
              </Typography>
              <Box 
                sx={{
                  position: 'relative',
                  mb: 5,
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-15px',
                    left: '0',
                    width: '80px',
                    height: '3px',
                    background: 'linear-gradient(90deg, rgba(0,229,255,1) 0%, rgba(0,229,255,0) 100%)',
                    borderRadius: '2px',
                  },
                }}
              >
                <Typography 
                  variant="h6" 
                  component="p" 
                  sx={{ 
                    lineHeight: 1.6,
                    color: 'rgba(255, 255, 255, 0.8)',
                  }}
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  Bringing best practices in Managed IT and Cybersecurity
                  Solutions to your organization.
                  <br />
                  Start building better defenses today.
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                mt={5}
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={isMd ? false : true}
                  sx={{ 
                    height: 54,
                    whiteSpace: 'nowrap',
                    minWidth: 180,
                    fontSize: '1.1rem',
                    py: 2, 
                    px: 4,
                    background: 'linear-gradient(90deg, rgba(0,229,255,0.2) 0%, rgba(0,229,255,0.4) 100%)',
                    border: '1px solid rgba(0,229,255,0.5)',
                    color: 'white',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: '-100%',
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(90deg, transparent, rgba(0,229,255,0.3), transparent)',
                      transition: 'all 0.6s ease',
                    },
                    '&:hover': {
                      boxShadow: '0 0 15px rgba(0, 229, 255, 0.7)',
                      background: 'linear-gradient(90deg, rgba(0,229,255,0.3) 0%, rgba(0,229,255,0.5) 100%)',
                      '&::before': {
                        left: '100%',
                      },
                    },
                    backdropFilter: 'blur(8px)',
                  }}
                  onClick={() => {
                    const element = document.getElementById('contact-form');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            alignItems={'center'}
            justifyContent={'center'}
            xs={12}
            md={6}
            data-aos="fade-left"
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >

              
              {/* Animated Nebula with Constellation */}
              <Box
                sx={{
                  width: '80%',
                  maxWidth: 500,
                  height: 400,
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 229, 255, 0.3)',
                  boxShadow: '0 0 30px rgba(0, 229, 255, 0.3)',
                  zIndex: 2,
                  position: 'relative',
                  overflow: 'hidden',
                  background: 'linear-gradient(135deg, rgba(0,5,20,0.95) 0%, rgba(10,20,50,0.9) 50%, rgba(0,10,30,0.95) 100%)',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(ellipse at center, rgba(30,50,180,0.1) 0%, rgba(0,0,20,0) 70%)',
                    opacity: 0.8,
                    animation: 'nebulaPulse 8s ease-in-out infinite',
                  },
                  '@keyframes nebulaPulse': {
                    '0%': { opacity: 0.5 },
                    '50%': { opacity: 0.8 },
                    '100%': { opacity: 0.5 },
                  },
                }}
              >
                {/* Nebula cloud patches */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '20%',
                    left: '10%',
                    width: '60%',
                    height: '40%',
                    borderRadius: '50%',
                    background: 'radial-gradient(ellipse at center, rgba(255,0,255,0.08) 0%, rgba(0,0,0,0) 70%)',
                    filter: 'blur(15px)',
                    animation: 'nebulaMove 20s ease-in-out infinite',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '30%',
                    right: '20%',
                    width: '50%',
                    height: '30%',
                    borderRadius: '50%',
                    background: 'radial-gradient(ellipse at center, rgba(0,229,255,0.08) 0%, rgba(0,0,0,0) 70%)',
                    filter: 'blur(15px)',
                    animation: 'nebulaMove 15s ease-in-out infinite reverse',
                    '@keyframes nebulaMove': {
                      '0%': { transform: 'scale(1) translate(0, 0)' },
                      '50%': { transform: 'scale(1.2) translate(10px, 5px)' },
                      '100%': { transform: 'scale(1) translate(0, 0)' },
                    },
                  }}
                />

                {/* Stars */}
                {[...Array(20)].map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'absolute',
                      top: `${Math.random() * 80 + 10}%`,
                      left: `${Math.random() * 80 + 10}%`,
                      width: `${Math.random() * 4 + 2}px`,
                      height: `${Math.random() * 4 + 2}px`,
                      borderRadius: '50%',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      boxShadow: '0 0 10px rgba(255, 255, 255, 0.8)',
                      animation: `starTwinkle ${Math.random() * 3 + 2}s ease-in-out infinite ${Math.random() * 2}s`,
                      '@keyframes starTwinkle': {
                        '0%': { opacity: 0.2, transform: 'scale(0.8)' },
                        '50%': { opacity: 1, transform: 'scale(1.2)' },
                        '100%': { opacity: 0.2, transform: 'scale(0.8)' },
                      },
                    }}
                  />
                ))}

                {/* Constellation Lines forming Shield */}
                <svg
                  width="100%"
                  height="100%"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0.7,
                  }}
                >
                  {/* Top of shield */}
                  <line x1="30%" y1="25%" x2="50%" y2="15%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  <line x1="50%" y1="15%" x2="70%" y2="25%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  
                  {/* Sides of shield */}
                  <line x1="30%" y1="25%" x2="30%" y2="55%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  <line x1="70%" y1="25%" x2="70%" y2="55%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  
                  {/* Bottom curves of shield */}
                  <line x1="30%" y1="55%" x2="40%" y2="75%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  <line x1="40%" y1="75%" x2="50%" y2="80%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  <line x1="50%" y1="80%" x2="60%" y2="75%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  <line x1="60%" y1="75%" x2="70%" y2="55%" style={{ stroke: 'rgba(0,229,255,0.7)', strokeWidth: '1.5px' }} />
                  
                  {/* Shield interior left empty for cleaner design */}
                </svg>

                {/* Constellation Points for Shield shape */}
                {/* Top center point */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '15%',
                    left: '50%',
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 4s infinite',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                  }}
                />
                
                {/* Top left point */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '30%',
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 3.5s infinite 0.5s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                  }}
                />
                
                {/* Top right point */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '70%',
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 4s infinite 1s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                  }}
                />
                
                {/* Middle left point */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '55%',
                    left: '30%',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 3s infinite 1.5s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                  }}
                />
                
                {/* Middle right point */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '55%',
                    left: '70%',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 4s infinite 2s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                  }}
                />
                
                {/* Bottom point */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '80%',
                    left: '50%',
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 4s infinite 3s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                  }}
                />
                
                {/* N pattern using SVG for precise control */}
                {/* N pattern lines with pulsing brightness effect */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '40%',
                    width: '20%',
                    height: '30%',
                  }}
                >
                  <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    {/* Left vertical line */}
                    <line 
                      x1="10" y1="0" 
                      x2="10" y2="100" 
                      stroke="rgba(0, 229, 255, 0.9)" 
                      strokeWidth="3"
                      className="n-line n-line-1"
                    />
                    
                    {/* Diagonal line */}
                    <line 
                      x1="10" y1="0" 
                      x2="90" y2="100" 
                      stroke="rgba(0, 229, 255, 0.9)" 
                      strokeWidth="3"
                      className="n-line n-line-2"
                    />
                    
                    {/* Right vertical line */}
                    <line 
                      x1="90" y1="0" 
                      x2="90" y2="100" 
                      stroke="rgba(0, 229, 255, 0.9)" 
                      strokeWidth="3"
                      className="n-line n-line-3"
                    />
                  </svg>
                </Box>
                
                {/* N connection points as separate Box elements to match shield styling */}
                {/* Top left dot */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%', // Align with top of SVG
                    left: '42%', // Align with left vertical line (10% of SVG width)
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 4s infinite 0.2s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                    zIndex: 2, // Ensure dots appear above lines
                  }}
                />
                
                {/* Bottom left dot */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '60%', // Align with bottom of SVG
                    left: '42%', // Align with left vertical line (10% of SVG width)
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 3.5s infinite 0.7s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                    zIndex: 2,
                  }}
                />
                
                {/* Top right dot */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%', // Align with top of SVG
                    left: '58%', // Align with right vertical line (90% of SVG width)
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 4s infinite 1.2s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                    zIndex: 2,
                  }}
                />
                
                {/* Bottom right dot */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '60%', // Align with bottom of SVG
                    left: '58%', // Align with right vertical line (90% of SVG width)
                    width: '7px',
                    height: '7px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 229, 255, 0.9)',
                    boxShadow: '0 0 15px rgba(0, 229, 255, 0.9)',
                    animation: 'starPulse 3.5s infinite 1.7s',
                    transform: 'translate(-50%, -50%)', // Center the dot precisely
                    zIndex: 2,
                  }}
                />
                
                {/* Keyframe animation for starPulse (keeping this for other elements) */}
                <Box
                  sx={{
                    display: 'none',
                    '@keyframes starPulse': {
                      '0%': { opacity: 0.5, transform: 'translate(-50%, -50%) scale(0.8)' },
                      '50%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1.2)' },
                      '100%': { opacity: 0.5, transform: 'translate(-50%, -50%) scale(0.8)' },
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroImageRight;
