import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import PolicyIcon from '@mui/icons-material/Policy';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ScienceIcon from '@mui/icons-material/Science';
import AOS from 'aos';
import 'theme/spaceTheme.css';

const mock = [
  {
    title: 'Comprehensive Security Solutions',
    subtitle:
      'Our team holds top certifications including CISSP, Security+, GCIH, SSCP, GSEC, and GCED. These qualifications ensure your business is protected with industry-standard cybersecurity practices that defend against the latest threats.',
    icon: <PolicyIcon fontSize="large" />,
  },
  {
    title: 'Cloud Infrastructure Expertise',
    subtitle:
      'As certified experts in both Amazon Web Services (AWS) and Microsoft Azure, we provide cloud-agnostic solutions tailored to your business. We help you optimize, secure, and scale your cloud infrastructure, no matter where your data resides.',
    icon: <CloudDoneIcon fontSize="large" />,
  },
  {
    title: '24/7 Monitoring and Threat Detection',
    subtitle:
      'Our certifications in tools like Nessus and Auvik ensure round-the-clock monitoring of your systems. We provide real-time alerts and proactive response to keep your environment secure at all times.',
    icon: <ScreenSearchDesktopIcon fontSize="large" />,
  },
  {
    title: 'Expertise Across IT Solutions',
    subtitle:
      'With certifications and expertise in VMware, Veeam, Microsoft 365, and VOIP solutions, we bring comprehensive IT management services to your business, ensuring seamless communication and reliable data protection.',
    icon: <ScienceIcon fontSize="large" />,
  },
];

const FeaturesWithSimpleIcons = () => {
  const theme = useTheme();
  
  // Refresh AOS when component mounts
  useEffect(() => {
    AOS.refresh();
  }, []);

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
              paddingBottom: '1em',
            }}
            gutterBottom
            color={'primary'}
            align={'center'}
            className="neon-text"
            data-aos="fade-up"
          >
            Certifications & Expertise
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
              paddingBottom: '1em',
            }}
            className="neon-text"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            Industry-Leading Certifications for Complete IT Solutions
          </Typography>
          <Typography
            variant="h6"
            align={'center'}
            color={'text.secondary'}
            paddingBottom={4}
            data-aos="fade-up"
            data-aos-delay={200}
          >
            Our certified experts provide industry-standard solutions in
            cybersecurity, cloud management, and IT infrastructure. <br />
            Trust our team to protect your business and enhance your technology
            landscape with the latest certifications.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid 
              key={i} 
              item 
              xs={12} 
              md={3}
              data-aos="fade-up"
              data-aos-delay={300 + (i * 100)}
            >
              <ListItem
                component="div"
                disableGutters
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 2,
                  height: '100%',
                  transition: 'all 0.3s ease-in-out',
                  borderRadius: 2,
                  border: '1px solid rgba(0, 229, 255, 0.1)',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 0 20px rgba(0, 229, 255, 0.2)',
                    border: '1px solid rgba(0, 229, 255, 0.3)',
                  },
                }}
              >
                <Box
                  component={ListItemAvatar}
                  marginBottom={2}
                  minWidth={'auto !important'}
                >
                  <Box 
                    color={theme.palette.primary.main}
                    sx={{
                      backgroundColor: 'rgba(0, 229, 255, 0.1)',
                      borderRadius: '50%',
                      padding: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 229, 255, 0.2)',
                        transform: 'scale(1.1)',
                      }
                    }}
                  >
                    {item.icon}
                  </Box>
                </Box>
                <ListItemText
                  primary={item.title}
                  secondary={item.subtitle}
                  primaryTypographyProps={{
                    variant: 'h6',
                    gutterBottom: true,
                    align: 'center',
                    className: 'neon-text',
                  }}
                  secondaryTypographyProps={{ align: 'center' }}
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontWeight: 700,
                    },
                    margin: 0,
                  }}
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default FeaturesWithSimpleIcons;
