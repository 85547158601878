import React, { useEffect, useMemo } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Import Helmet and HelmetProvider
import Page from 'components/Page';
import { Topbar, Footer } from 'layouts/Fixed/components';
import HeroVideo from 'components/HeroVideo';
import FeatureCheckMarks from 'components/FeatureCheckMarks';
import FeatureCards from 'components/FeatureCards';
import Form from 'components/Form';
import servicesData from './assets/services.json';
import ScrollToTop from 'components/ScrollToTop';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/material'; // Import Container component
import './theme/spaceTheme.css'; // Import the space theme CSS

function App() {

  useEffect(() => {
    // AOS initialization moved to Page.js for consistency
    // Just refresh AOS when component mounts
    AOS.refresh();

    // Add dark-mode class to body for star background effect
    document.body.classList.add('dark-mode');
  }, []);

  // Create theme with dark mode always enabled
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
        },
      }),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>
        <div style={{ overflowX: 'hidden' }}>
          {/* SEO Metadata for the homepage */}
          <Helmet>
            <title>Nebula Networks | Managed IT and Cybersecurity Solutions</title>
            <meta
              name='description'
              content='Nebula Networks is your trusted partner for Managed IT, Cybersecurity, Cloud Solutions, and IT infrastructure services. Empowering businesses with expert IT solutions, led by industry pioneers.'
            />
            <meta
              property='og:title'
              content='Nebula Networks | Managed IT and Cybersecurity Solutions'
            />
            <meta
              property='og:description'
              content='Nebula Networks provides expert Managed IT, Cybersecurity, Cloud Solutions, and IT Infrastructure services.'
            />
            <meta
              property='og:image'
              content='https://nebulanetworks.net/assets/logo_light_transparent.png'
            />
            <meta property='og:url' content='https://nebulanetworks.net' />
            <meta name='twitter:card' content='summary_large_image' />
            <meta
              name='twitter:title'
              content='Nebula Networks | Managed IT and Cybersecurity Solutions'
            />
            <meta
              name='twitter:description'
              content='Nebula Networks is your trusted partner for Managed IT, Cybersecurity, and Cloud Solutions.'
            />
            <meta
              name='twitter:image'
              content='https://nebulanetworks.net/assets/logo_light_transparent.png'
            />
          </Helmet>

          <Page>
            <ScrollToTop />
            <Topbar />

            {/* Hero Section */}
            <HeroVideo />

            {/* Features Section */}
            <Box sx={{ mt: 8, mb: 8 }}>
              <div data-aos='fade-right' className="cyber-grid">
                <FeatureCheckMarks />
              </div>
            </Box>

            {/* Services Section */}
            <Box sx={{ mt: 10, mb: 10 }}>
              <div data-aos='fade-left'>
                <FeatureCards 
                  services={servicesData.services} 
                  heading="OUR SERVICES" 
                  subtitle={servicesData.subtitle} 
                />
              </div>
            </Box>

            {/* Contact Form with integrated Headline */}
            <Box className="cyber-grid">
              <Container data-aos="fade-up">
                <Form />
              </Container>
            </Box>
            {/* Footer Section */}
            <Footer />
          </Page>
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
